<template>
  <table class="integration_table text-left flex-1 h-full">
    <thead class="text-[#757A8A] bg-[#F4F6FA] font-semibold text-[0.875rem] font-poppins">
      <tr>
        <td class="py-3 pl-3 w-80">Integration</td>
        <td class="py-3 w-10">Configuration</td>
      </tr>
    </thead>
    <tbody v-if="loader">
      <tr>
        <td colspan="2">
          <div class="p-5 text-center">
            <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="integrations && integrations.length == 0">
      <tr>
        <td colspan="2" class="">
          <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
            <i class="fab fa-bandcamp" aria-hidden="true"></i>
            No Integrations Available
          </p>
        </td>

      </tr>
    </tbody>
    <tbody
      v-else-if="integrations && integrations.length > 0"
      class="font-poppins text-[#3C4549] text-[0.875rem]"
    >
      <tr
        :key="index"
        v-if="integrations && integrations.length > 0 && item.key != 'drip'"
        v-for="(item, index) in integrations"
        class="border-b border-[#ECEEF5] px-3 max-h-[5.07rem]"
      >
        <td class=" pl-3 w-80">
          <div class="h-full py-4 ">
            <div class="grid gap-x-3 w-full" style="grid-template-columns: 2.28rem auto;">
              <div class="  ">

                <img class=" h-[2.28rem] w-[2.28rem] rounded-full " :src="item.logo" alt=""  />
              </div>
              <div class=" grid grid-rows-2  ">
                <p
                  class="text font-bold !text-[#3C4549]"
                >
                  {{ item.name }}
                </p>
                <p
                  class="text text-[0.75rem]"
                >
                  {{ item.description }}
                </p>
              </div>
            </div>


          </div>
        </td>
        <td class="w-10">
          <a
            v-if="item.custom && item.meta==null"
            class="m-0 rounded-[0.3rem] transition-all ease-in-out  justify-center text-center font-medium font-poppins items-center gap-2.5 inline-flex px-[1rem] py-[0.5rem] text-xs leading-none !text-white ml-2 !bg-blue-600 hover:bg-blue-700"
            :href="item.routeName">Connect</a>
          <Button
            v-else
            id="connect-button"
            type="button"
            class="text-white ml-2 !bg-blue-600 hover:bg-blue-700"
            buttonClass="btn-sm"
            @click="$router.push({ name: item.routeName })"
          >
            <template v-slot:label>Connect</template>
          </Button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ROLES } from "@/common/constants";

export default {
  name: "Available-Integrations",
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },
  data() {
    return {
      ROLES: ROLES,
    };
  },
  props: {
    loader: {
      type: Boolean,
      required: true,
      default: false,
    },
    integrations: {
      type: [Object,Array],
      required: true,
      default: false,
    },
  },
  computed: {
    isDashboard() {
      return this.$route.name === "dashboard";
    },
  },
  methods: {},
};
</script>

<style lang="less">
.integration_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 13.8rem);
    table-layout: fixed;
  }
  thead,
  thead tr,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.my_inner_dropdown {
  .dropdown-menu {
    left: -10px !important;
  }
}
</style>
