<template>
  <div>
    <div class="flex px-3 py-[14px] justify-between items-center">
      <div class="w-full flex gap-x-2"></div>
      <div class="w-full flex justify-center items-center">
        <p class="text-[#3C4549] !text-[1.125rem] mr-1 font-bold font-poppins leading-none">
          Integrations
        </p>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
        >
          <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
              fill="#3C4549"
          />
        </svg>
      </div>
      <div class="w-full flex items-center justify-center">
        <Button
            id="importCSV"
            type="button"
            :class="[ (activeButton === 'Available-Integrations') ? 'text-white bg-[#013D87] !border-[#013D87]' : '!text-[#757A8A] !border-r-transparent']"
            class="!rounded-tr-none !rounded-br-none border"
            buttonClass="btn-md"
            @click="TableChange('available')"
        >
          <template v-slot:label>Available Integrations</template>
        </Button>
        <Button
            id="bulklinks"
            type="button"
            :class="[ (activeButton === 'Configured-Integrations') ? 'text-white bg-[#013D87] !border-[#013D87]' : '!text-[#757A8A]']"
            class="!rounded-tl-none !rounded-bl-none border"
            buttonClass="btn-md"
            @click.prevent="TableChange('configured')"
        >
          <template v-slot:label>Configured Integrations</template>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    SwitchButton: () => import("@/ui/ui-kit/v2/SwitchButton.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },
  data() {
    return {
      activeButton: "Available-Integrations",
    };
  },
  methods: {
    TableChange(option) {
      switch (option) {
        case "available":
          this.activeButton = "Available-Integrations";
          break;
        case "configured":
          this.activeButton = "Configured-Integrations";
          break;
        default:
      }
      this.$emit('ToggleTable', this.activeButton)
    },
  },
};
</script>
