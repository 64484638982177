<template>
  <div class="setting_form h-full" >
    <b-modal
      modal-class="modal_basic addServiceLists sm-modal"
      id="addServiceLists"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div class="w-full h-full">
        <div
          @click="$bvModal.hide('addServiceLists')"
          class="float-right w-6 h-6 flex justify-end"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3 h-3 cursor-pointer"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z"
              fill="black"
            />
          </svg>
        </div>
        <div
          class="w-full h-full px-10 flex flex-col items-center justify-between pt-2"
        >
          <div class="flex flex-col items-center justify-center">
            <p
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[0.875rem] font-bold leading-5"
            >
              Select your account details
            </p>
          </div>

          <div class="flex flex-col justify-center item-center w-full">
            <template v-if="service_lists">
              <FloatingLabelInput
                id="first_name"
                v-if="service_name == 'aweber'"
                class="mb-3 w-full"
                customClass="h-[56px] bg-[#F4F6FA]"
                type="text"
                label="Enter account name"
                v-model="name"
                placeholder="Enter account name"
              >
              </FloatingLabelInput>
              <!-- Integration List -->
              <div
                class="flex flex-col justify-start w-full"
                v-if="service_lists.length > 0"
              >
                <div
                  class="flex flex-row justify-between items-center w-full px-8 py-3 bg-[#F4F6FA]"
                >
                  <p
                    class="flex-1 text-[#3C4549] text-[0.875] font-poppins leading-none"
                  >
                    Select your lists which you want to integrate with
                    {{ getSiteDetails.agency_name }}
                  </p>
                </div>

                <div
                  class="flex flex-row justify-between items-center w-full px-8 py-3 border-t border-t-[#F2F3F8]"
                  v-for="list in service_lists"
                  :key="list.id"
                >
                  <p
                    class="flex-1 text-[#757A8A] text-[0.875] font-poppins leading-none"
                  >
                    {{ list.name }}
                  </p>
                  <Checkbox
                    :value="list"
                    :id="list.id"
                    type="checkbox"
                    v-model="selected_lists"
                    labelClass="text-[#757A8A]"
                    :isDisabled="false"
                  ></Checkbox>
                </div>
              </div>
            </template>
            <template v-else>
              <p
                class="text-[#3C4549] font-poppins pt-[1.188rem] text-[0.875rem] font-bold leading-5"
              >
                You do not have any lists in your account, please create list in
                your account first.
              </p>
            </template>
          </div>

          <!-- end -->

          <div class="mt-2 flex gap-x-3 justify-between items-center">
            <Button
              id="close-button"
              type="button"
              class="border border-zinc-700 !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$bvModal.hide('addServiceLists')"
            >
              <template v-slot:label>Close</template>
            </Button>

            <Button
              id="save-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              :disabledProp="service_lists.length == 0"
              @click.prevent="saveLists()"
            >
              <template v-slot:label>Save</template>
            </Button>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="w-full h-full bg-[#F7F7FC]">
        <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-[8px]"
        >
          <div class="flex flex-col justify-between h-full">
            <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
              <IntegrationsTableHeader
                @ToggleTable="ToggleTable"
              ></IntegrationsTableHeader>
              <AvailableIntegrationsTable
                v-if="TableToShow === 'Available-Integrations'"
                :loader="loader"
                :integrations="integrations"
              ></AvailableIntegrationsTable>
              <ConfiguredIntegrationsTable
                v-else-if="TableToShow === 'Configured-Integrations'"
                :loader="loader"
                :integrations="integrations"
                @reconnect="reconnect"
                @removeItem="remove"
              ></ConfiguredIntegrationsTable>
            </div>
            <!-- <TeamPaginationV2></TeamPaginationV2>  -->
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getAuthorizationURL,
  removeIntegrationURL,
  reconnectIntegrationURL,
  fetchIntegrationListURL,
  getIntegrationMessageURL,
} from "@/config/config.js";
import GuidedTour from "@/views/guided-tour/GuidedTour.vue";
import { DARK_PURPLE_LOADER_COLOR } from "@/common/constants";
import http from "@/mixins/http-lib";
import vueCookie from "vue-cookie";
import {
  AWEBER_KEY,
  cookieAppDomain,
  INTEGRATION_COOKIE,
} from "../../../constants";
import {
  notAllowedIntegrationsInWhitelabel,
  hiddenIntegrationList,
} from "@/common/attributes";
import IntegrationsTableHeader from "./dialogs/integrationsTableHeader.vue";
import AvailableIntegrationsTable from "./dialogs/AvailableIntegrationsTable.vue";
import ConfiguredIntegrationsTable from "./dialogs/ConfiguredIntegrationsTable.vue";

export default {
  components: {
    GuidedTour,
    IntegrationsTableHeader,
    AvailableIntegrationsTable,
    ConfiguredIntegrationsTable,
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },
  data() {
    return {
      TableToShow: "Available-Integrations",
      authorizationURL: {
        aweber: null,
      },
      service_name: null,
      service_lists: [],
      selected_lists: [],
      account_id: null,
      loader: true,
      size: "20px",
      color: DARK_PURPLE_LOADER_COLOR,
      name: "",
      toggleIntegrationTabState: "configured",

      integrations: [],
      integrationList: [],
      notAllowedIntegrationsInWhitelabel,
      hiddenIntegrationList,
    };
  },
  async created() {
    await this.getAuthorization();
    await this.fetchIntegrationList();
    if (this.$route.query && this.$route.query.message) {
      this.alertMessage(this.$route.query.message);
    }
    this.getIntegrationMessage();
  },
  computed: {
    ...mapGetters(["getGuidedTour", "getProfile", 'getWorkspace']),
  },
  methods: {
    ...mapActions(["setLinksFetchStatus", "fetchProfile"]),
    ToggleTable(TableName) {
      console.log(TableName);
      switch (TableName) {
        case "Available-Integrations":
          this.TableToShow = "Available-Integrations";
          break;
        case "Configured-Integrations":
          this.TableToShow = "Configured-Integrations";
          break;
        default:
      }
    },

    async getAuthorization() {
      return http.post(getAuthorizationURL).then(
        (response) => {
          if (response.data.status) {
            this.authorizationURL = response.data.authorization;
            //todo: Remove this as we are not setting the aweber_secret when comment
            // vueCookie.set(AWEBER_KEY, response.data.authorization.aweber_secret, {
            //   expires: '1D',
            //   domain: cookieAppDomain
            // })
          }
          return false;
        },
        (err) => {
          this.alertMessage(err.message, "error");
        }
      );
    },

    remove(item) {
      let self = this;
      swal(
        {
          title: "Are you sure ?",
          type: "warning",
          text: "Your associated link campaigns for this account will be paused.",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, Cancel!",
          closeOnConfirm: true,
          customClass: "sweetAlert_box",
          closeOnCancel: true,
          animation: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.loader = true;
            http
              .post(removeIntegrationURL, {
                type: item.key,
                account_id: item.accountId,
                integration_id: item._id,
              })
              .then(
                async (response) => {
                  if (response.data.status) {
                    self.alertMessage(response.data.message, "success");
                    await self.getAuthorization();
                    await self.fetchProfile();
                    await self.fetchIntegrationList();
                  }
                },
                (err) => {
                  this.alertMessage(err.message, "error");
                }
              );
          }
        }
      );
    },

    /**
     * Method sets the cookie for integration settings and redirects to the desired integration url
     */
    reconnect(item) {
      let self = this;
      swal(
        {
          title: "Are you sure ?",
          type: "warning",
          text: "If you reconnect with some different account, your added link campaigns will be paused.",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, I Understand!",
          cancelButtonText: "No, Cancel!",
          closeOnConfirm: true,
          customClass: "sweetAlert_box",
          closeOnCancel: true,
          animation: false,
        },
        function (isConfirm) {
          if (!isConfirm) return;
          if (!item.accountId) {
            document.location.href = url;
            return;
          }
          /**
           * if saveCookie exists, save the integration config in cookie,
           * otherwise use the conventional way of saving it in server session.
           * TODO: We need to make sure we update every integration to cater this via cookie
           * instead of an extra call for saving session.
           * Once all integrations are upgraded, this block of condition will be removed.
           *
           * Integrated:
           * BenchmarkEmail, ConstantContact, Gist, Mautic, Sendinblue, SendLane
           * GetResponse, FreshMail, ConvertKit, Moosend, MailChimp, MailerLite
           * SendPulse
           *
           */
          if (!!item.saveCookie) {
            const integration = {
              account_id: item.accountId,
              type: item.key,
              expire: item.expire,
              integration_id: item._id,
            };
            vueCookie.set(INTEGRATION_COOKIE, JSON.stringify(integration));
            if (item.custom && !item.meta) {
              document.location.href = item.routeName;
              return true;
            }

            self.$router.push({
              name: item.routeName,
            });
            return;
            Button;
          }

          http
            .post(reconnectIntegrationURL, {
              account_id: item.accountId,
              type: item.key,
              expire: item.expire,
              integration_id: item._id,
            })
            .then(
              (response) => {
                if (response.data.status) {
                  if (item.custom && !item.meta) {
                    (document.location.href = item.routeName), true;
                  } else {
                    self.$router.push({
                      name: item.routeName,
                    });
                  }
                }
              },
              (err) => {
                this.alertMessage(err.message, "error");
              }
            );
        }
      );
    },

    /**
     * Based on the integration_id in the url, we send the request to backend to fetch the lists of that specific integration.
     * If lists are not available, we issue the desired message
     */
    getIntegrationMessage() {
      this.name = "";
      http
        .post(getIntegrationMessageURL, {
          integration_id: this.$route.query.integration_id,
        })
        .then(
          (response) => {
            if (response.data.status) {
              this.alertMessage(response.data.message, "success");
              if (response.data.type) {
                if (response.data.type === "email") {
                  try {
                    this.service_lists = response.data.lists;
                    this.service_name = response.data.service;
                    this.account_id = response.data.account_id;
                    this.$bvModal.show("addServiceLists");
                  } catch (m) {
                    this.alertMessage(m.message, "error");
                  }
                }
              }
              return;
            }

            if (response.data.status === false && response.data.message) {
              this.alertMessage(response.data.message, "error");
            }
          },
          (err) => {
            this.alertMessage(err.message, "error");
          }
        );
    },

    /**
     * After successful connection with an integration, we select amongst the lists fetched from the integration.
     * selected lists are saved in the application
     */
    saveLists() {
      let thiss = this;
      if (this.selected_lists.length === 0) {
        this.alertMessage("Please select atleast one list.", "error");
        return;
      }
      if (
        this.service_name == "aweber" &&
        (!this.name || this.name.trim().length == 0)
      ) {
        this.alertMessage("Please enter project name", "error");
        return;
      }

      let post_data = {
        lists: this.selected_lists,
        id: this.account_id,
        integration_id: this.$route.query.integration_id,
      };
      let postURL = null;
      const selectedIntegration = this.integrationList.find(
        (integration) => integration.key === this.service_name
      );
      /**
       * In case of aweber, we have to submit the project name. Therefore the dynamic array of integration contains a key
       * called 'allowName' only if that specific integration requires name in its payload
       */
      if ("allowName" in selectedIntegration) {
        post_data["name"] = this.name;
      }
      postURL = selectedIntegration.saveListURL;
      http.post(postURL, post_data).then(
        (response) => {
          if (response.data.status) {
            this.alertMessage(response.data.message, "success");
            this.getUserProfile();
            thiss.$bvModal.hide("addServiceLists");
            return;
          }
          this.alertMessage(response.data.message, "error");
        },
        (err) => {
          this.alertMessage(err.message, "error");
        }
      );
    },

    /**
     * Method is responsible to fetch a list of integrations. All meta data related to the integrations to display it in integrations section
     */
    async fetchIntegrationList() {
      return http.post(fetchIntegrationListURL).then(
        (res) => {
          if (res.data.status) {
            this.integrationList = res.data.data;
            this.integrations = this.integrationList.map((item) => {
              return {
                key: item.key,
                whitelabel: !this.notAllowedIntegrationsInWhitelabel.includes(
                  item.key
                ),
                isHide: this.hiddenIntegrationList.includes(item.key),
                logo: item.logo,
                name: item.value,
                custom: item.custom,
                meta: item.meta,
                saveCookie: !!item.saveCookie,
                description: `Send subscribers directly to ${item.value} from ${this.getSiteDetails.agency_name}`,
                length: this.getProfile[item.key]
                  ? this.getProfile[item.key].length || 0
                  : 0,
                routeName: this.getRouteName(item.key),
                expire: this.getProfile[item.key]
                  ? this.getProfile[item.key] && this.getProfile[item.key][0]
                    ? this.getProfile[item.key][0].expire || ""
                    : ""
                  : "",
                accountName: this.getProfile[item.key]
                  ? this.getProfile[item.key] && this.getProfile[item.key][0]
                    ? this.getProfile[item.key][0].name || ""
                    : ""
                  : "",
                accountId: this.getProfile[item.key]
                  ? this.getProfile[item.key] && this.getProfile[item.key][0]
                    ? this.getProfile[item.key][0].account_id || ""
                    : ""
                  : "",
                _id: this.getProfile[item.key]
                  ? this.getProfile[item.key] && this.getProfile[item.key][0]
                    ? this.getProfile[item.key][0]._id || ""
                    : ""
                  : "",
              };
            });
            this.integrations = this.getSiteDetails.isWhiteLabel
              ? this.integrations.filter((item) => item.whitelabel)
              : this.integrations;
            this.integrations = this.integrations.filter(
              (item) => !item.isHide
            );
          }
          this.loader = false;
          return true;
        },
        (err) => {
          this.alertMessage(err.message, "error");
          this.integrationList = [];
          this.integrations = [];
          this.loader = false;
        }
      );
    },
    getRouteName(key) {
      const integration = this.integrationList.find(
        (integration) => integration.key == key
      );
      return integration.custom
        ? integration.meta
          ? integration.meta
          : this.authorizationURL[integration.key]
        : key;
    },
  },
  watch: {
   async 'getWorkspace._id' (value) {
      await this.fetchProfile();
      await this.getAuthorization();
      await this.fetchIntegrationList();
      if (this.$route.query && this.$route.query.message) {
        this.alertMessage(this.$route.query.message);
      }
      this.getIntegrationMessage();
    }
  }
};
</script>

<style scoped lang="less">
.integration_config {
  .color---red {
    span {
      &:hover {
        border-bottom: 1px solid #f76a60;
      }
    }
  }
}

.total_integrations {
  position: absolute;
  font-size: 12px;
  background-color: red;
  width: 20px;
  text-align: center;
  border: 1px solid red;
  border-radius: 25px;
  margin-left: 130px;
  margin-top: -17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.total_configured_integrations {
  position: absolute;
  font-size: 12px;
  background-color: red;
  width: 20px;
  text-align: center;
  border: 1px solid red;
  border-radius: 25px;
  margin-left: 290px;
  margin-top: -17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
</style>
