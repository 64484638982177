<template>
  <table class="configured_table text-left flex-1 h-full">
    <thead
      :class="[this.isDashboard ? 'bg-white border-b' : 'bg-[#F4F6FA]']"
      class="text-[#757A8A] font-semibold text-[0.875rem] font-poppins"
    >
      <tr>
        <td class="py-3 pl-3 w-60">Integration</td>
        <td class="py-3 text-left w-60">Account Name</td>
        <td class="py-3 text-center w-40">Status</td>
        <td class="py-3 text-center w-60">Configuration</td>
        <td class="py-3 pr-4 text-center w-28">More</td>
      </tr>
    </thead>
    <tbody v-if="loader">
      <tr>
        <td colspan="5">
          <div class="p-5 text-center">
            <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="!(integrations && integrations.filter(item => item.length > 0).length > 0)">
      <tr>
        <td colspan="5" class="">
          <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
            <i class="fab fa-bandcamp" aria-hidden="true"></i>

            You have not integrated any third-party application yet.
          </p>
        </td>

      </tr>
    </tbody>
    <tbody
      v-else-if="integrations && integrations.filter(item => item.length > 0).length > 0"
      class="font-poppins text-[#3C4549] text-[0.875rem]"
    >
      <tr
      v-if="item && item.length > 0 && !loader" v-for="(item,index) in integrations"
        class="border-b border-[#ECEEF5] px-3"
      >
         <td class="py-4 pl-3 w-60">
          <div class="h-full">
            <div class="grid gap-x-3 w-full" style="grid-template-columns: 2.28rem auto;">
              <div class=" h-[2.5rem] w-[2.5rem] mr-2 ">
                <img class="rounded-full h-full w-full"  :src="item.logo" alt="" />
              </div>
              <div class=" grid grid-rows-1 items-center ">
                <p
                  class="text-[#3C4549] text-sm font-bold font-poppins leading-none"
                >
                  {{ item.name }}
                </p>

              </div>
            </div>
          </div>
        </td>
        <td class="py-4 text-left w-60 truncate">
          {{ item.accountName }}
        </td>
        <td class="py-4 text-[#3ABA6D] w-40 font-semibold text-center">
          Connected
        </td>

        <td class="py-4 text-center w-60">
          <Button
            id="Reconnect-button"
            type="button"
            class="text-white bg-blue-600 !rounded-[8px] hover:bg-blue-700"
            buttonClass="btn-sm"
            @click.prevent="$emit('reconnect',item)"
          >
            <template v-slot:label>Reconnect</template>
          </Button>
        </td>

        <td class="py-4 pr-3 text-center w-28">
          <Button
            id="Remove-button"
            type="button"
            class="min-w-[6.14rem] !text-[#C13A52] h-[2.2rem] bg-transparent hover:bg-transparent"
            buttonClass="btn-md"
            @click.prevent="$emit('removeItem',item)"
          >
            <template v-slot:label>Disconnect</template>
          </Button>
        </td>
      </tr>
    </tbody>

  </table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ROLES } from "@/common/constants";

export default {
  name: "Configured-Integrations",
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },
  data() {
    return {

      ROLES: ROLES,
    };
  },
  props: {
    loader: {
      type: Boolean,
      required: true,
      default: false,
    },
    integrations: {
      type: [Object,Array],
      required: true,
      default: false,
    },
  },
  computed: {
    isDashboard() {
      return this.$route.name === "dashboard";
    },
  },
  methods: {

  },
};
</script>

<style lang="less">
.configured_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 17.2rem);
    table-layout: fixed;
  }
  thead,
  thead tr,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.my_inner_dropdown {
  .dropdown-menu {
    left: -10px !important;
  }
}
</style>
